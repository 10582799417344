@media (max-width: 767px) {
  .ant-layout-header {
    padding: 0 !important;
    height: 52px;
    line-height: 52px;
  }
  .ant-dropdown {
    min-width: 100% !important;
  }
  .ant-layout-content {
    margin: 0 !important;
  }
  .content__container {
    padding: 12px !important;
    height: calc(100vh - 52px);
  }
}

.content__container {
  background: #fff;
  overflow: auto;
}

.menu-burger {
  display: flex;
  gap: 16px;
  color: #f4f1ee;
  height: 100%;
}
