.hamburger {
  width: 28px;
  height: 28px;
}

.hamburger-button {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.hamburger__bar {
  transition-property: transform;
  transition-duration: 0.3s;
  transform-origin: center;
  stroke: white;
  stroke-width: 10%;
}

.hamburger__bar--top {
  transform: translateY(-40%);
}

.hamburger__bar--bot {
  transform: translateY(40%);
}

.hamburger-button--is-opened .hamburger__bar--top {
  transform: rotate(45deg);
}

.hamburger-button--is-opened .hamburger__bar--mid {
  transform: scaleX(0.1);
}

.hamburger-button--is-opened .hamburger__bar--bot {
  transform: rotate(-45deg);
}
