.logo,
.location {
  font-size: 24px;
  color: #f4f1ee;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}

.logo {
  height: 64px;
  line-height: 64px;
  margin-left: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 12px;
}

.content__container {
  background: #fff;
  overflow: auto;
}

.desktop-main-layout .content__container {
  scrollbar-color: #d1d5dc #fff;
  scrollbar-width: thin;
}

.location {
  margin-left: 56px;
}

.user-section {
  display: flex;
  gap: 16px;
  color: #f4f1ee;
  height: 64px;
  align-items: center;
  margin-right: 8px;
}

.user-section__fullname {
  cursor: default;
  font-weight: 500;
  font-size: 16px;
}

.user-section__button {
  color: #f4f1ee;
  border: none;
  line-height: 64;
}

.desktop-menu {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d1d5dc #fff;
  scrollbar-width: thin;
}

.desktop-main-layout ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.desktop-main-layout ::-webkit-scrollbar-track {
  background: #fff;
}

.desktop-main-layout ::-webkit-scrollbar-thumb {
  background: #d1d5dc;
}

.desktop-main-layout ::-webkit-scrollbar-thumb:hover {
  background: #b9c1ce;
}

#desktop-sider-collapser {
  display: none;
}

.unselectable-text {
  cursor: default;
}
