.status-radio.ant-radio-button-wrapper-checked:hover,
.status-radio.ant-radio-button-wrapper-disabled:hover,
.status-radio.ant-radio-button-wrapper-disabled {
  color: #d9d9d9 !important;
}

.status-radio_enabled.ant-radio-button-wrapper-checked {
  background-color: #006400 !important;
  border-color: #006400 !important;
}

.status-radio_enabled:hover {
  color: #006400 !important;
}

.status-radio_enabled:focus-within {
  box-shadow: 0 0 0 3px #00640020 !important;
}

.status-radio_disabled.ant-radio-button-wrapper-checked {
  background-color: #8b0000 !important;
  border-color: #8b0000 !important;
}

.status-radio_disabled:hover {
  color: #8b0000 !important;
}

.status-radio_disabled:focus-within {
  box-shadow: 0 0 0 3px #8b000020 !important;
}

.status-radio_disabled::before {
  background-color: #d9d9d9 !important;
}
